import React from 'react';

const Error = () => {
    return (
        <div className="row middle-lg center-lg error-main" >
            <div style={{ width: '40%'}}>
                <div className="row" style={{fontSize: "2rem", marginBotton: '1rem', textAlign:"center"}}>An error has occurred. Please contact the EPIC Help Desk for further assistance.</div>
                <hr />
                <div className="row center-lg" style={{ fontSize: '1.5rem', marginTop: '1rem', width: '100%'}}>Phone: 1-571-387-4024 <span style={{marginLeft: '5rem'}}>Email: EPIChelpdesk@dea.gov</span></div>
            </div>
        </div>
    );
}

export default Error;