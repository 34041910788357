import { createContext } from "react";
// import parseJwt from "../utils/parseJWT";
import parseJwt from '../utils/parseJwt';
import { ICAMToken } from "../mock/ICAMToken";

const redirectCallback = () => {
    window.history.replaceState({}, document.title, window.location.pathname);
}

class AuthStore {
    accessToken = localStorage.getItem('accessToken');

    loadUser() {
        const IS_LOCAL = !process.env.NODE_ENV;
        
        // if (IS_LOCAL) {
        //     const mockUserName = 'Test Local User';
        //     this.user = mockUserName;
        //     this.accessToken = ICAMToken;
        //     localStorage.setItem('userName', mockUserName);
        //     localStorage.setItem('accessToken', ICAMToken);
        // }

        if (window.location.href.includes('id_token=')) {
            const url = window.location.href;
            const params = url.split('#').slice(1)[0];
            const token = params.split('id_token=').pop().split('&state=')[0];
            const decoded = parseJwt(token);

            let stringifiedToken = '';
            if (decoded) {
                stringifiedToken = JSON.stringify(decoded);
            }

            const userName = decoded.sub;

            localStorage.setItem(
                'userName',
                userName === '' ? 'Error' : userName
            );

            localStorage.setItem(
                'accessToken',
                stringifiedToken === '' ? 'Error' : stringifiedToken
            );

            this.user = userName;
            this.accessToken = stringifiedToken;
            redirectCallback();
        }
    }

    removeUser() {
        localStorage.setItem('userName', 'Test User');
        localStorage.setItem('accessToken', '');
        this.user = 'Test User';
        this.accessToken = '';
    }
}

export default createContext(new AuthStore());