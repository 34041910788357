import React from 'react';

const Loading = () => {
    return (
        <div className="row middle-lg center-lg error-main" >
            <div style={{ width: '40%'}}>
                <div className="row" style={{fontSize: "2rem", marginBotton: '1rem', textAlign:"center"}}>Loading applications list, please wait...</div>
                <hr />
            </div>
        </div>
    );
}

export default Loading;