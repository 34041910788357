import React, { useState } from 'react';
// import { Button } from '../ui';
// import { TicketIcon } from '../icons';
import  { SupportTicketModal } from '../modal';
import { useGetAppSettings } from '../../hooks';

const Footer = () => {
    const [showSupportTicketModal, setShowSupportTicketModal] = useState(false);

    const {
        data: settings,
        isLoading: isLoadingSettings,
        // isError: errorSettings
    } = useGetAppSettings({});

    // const toggleSupportTicketModal = () => {
    //     setShowSupportTicketModal(!showSupportTicketModal);
    // }

    return (
        <div className='row middle-lg app-footer'>
            <div className="col-lg">
                {/* <div className="row app-footer-disclaimer">
                    Disclaimer: This computer is using anti-virus and anti-spyware software with the latest definitions and updates. Click here to read more.
                </div>
                <div className="row app-footer-contact">
                    Contact Helpdesk: <span className="app-footer-contact-phone">Phone: 571-362-HELP (4357)</span> <span className="app-footer-contact-email">Email: DEA.Servie.Desk@usdoj.gov</span>
                </div> */}
                {
                    !isLoadingSettings &&
                    settings.helpText.map((text, index) => (
                        <div key={`footer-text-${index}`} style={{margin: '0.15rem 0', textAlign: 'center'}}>
                            {text}
                        </div>
                    ))
                }
            </div>
            {/*    
            <div className="col-lg-3">       
                <div className="row end-lg">
                <Button 
                    buttonType='support-ticket-btn' 
                    actionType='button'
                    callback={() => setShowSupportTicketModal(!showSupportTicketModal)}
                >
                    <TicketIcon width={60} height={30} iconColor='white'/> Create Support Ticket
                </Button>
                </div>
                 
            </div>
            */}
            <SupportTicketModal 
                open={showSupportTicketModal}
                onClose={setShowSupportTicketModal}
            />
        </div>
    );
}

export default Footer;