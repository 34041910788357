import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login, Main, ProtectedPage } from './pages';
import { ReactQueryDevtools } from 'react-query/devtools';

const App = () => {
    return ( 
        <div>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/home" element={
                        <ProtectedPage>
                            <Main />
                        </ProtectedPage>
                    } />
                </Routes>
            <ReactQueryDevtools position='bottom-right' />
        </div>
    );
};

export default App;