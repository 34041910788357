const SERVER_DATA = window.SERVER_DATA;

export const getEnvironment = () => {
    let env = '';

    const hostname = window.location.href;

    if (hostname.includes('localhost')) {
        env = 'local';
    } else if (hostname.includes('dev') || hostname.includes('icam-1.devx.ext')) {
        env = 'dev';
    } else if (hostname.includes('pps')) {
        env = 'pps';
    } else if (hostname.includes('coop')) {
        env = 'coop';
    } else {
        env = 'prod';
    }

   return env;
}