import React from 'react';
import { Footer, AppDisplay, User } from '../components/layout';

const Main = () => {
    return (
        <div className="row container">
            <div className='main-container'>
                <div className="app-main">
                    <User />
                    <AppDisplay />
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Main;