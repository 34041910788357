import React from 'react'

const Button = ({ buttonType, actionType, children, callback, disabled }) => {
    const setButtonClass = () => {
        switch (buttonType) {
            case 'login-standard':
                return 'login-btn';

            case 'user-btn':
                return 'app-user-btn';

            case 'support-ticket-btn': 
                return 'app-support-btn';
            
            case 'modal-primary':
                return 'modal-standard';

            case 'modal-secondary':
                return 'modal-secondary';

            defaut:
                return 'login-btn';
        }
    }

    return (
        <button
        className={`${setButtonClass()}`}
        type={actionType}
        onClick={callback}
        disabled={disabled}
        >
            {children}
        </button>
    )
}

export default Button;