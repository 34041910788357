import React from 'react';
import { useGetUserInfo } from '../hooks';
import { Error, Loading, ErrorLogin } from '../pages';

const ProtectedPage = ({ children }) => {
    const {
        data: userInfo,
        isLoading: isLoadingUserInfo,
        isError: isUserInfoError
    } = useGetUserInfo({});

    if (isLoadingUserInfo)
        return <Loading />;

    if (!isLoadingUserInfo && !userInfo?.authenticated) 
        return <ErrorLogin />;

    if (isUserInfoError)
        return <Error />

    return children;
}

export default ProtectedPage;