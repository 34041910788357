import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import { ToastContainer } from 'react-toastify';
import App from './App';
import '@drewbot/sass-flexbox-grid/public/sass-flexbox/scss/main.scss';
import './styles/main.scss';

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={''}>
        <QueryClientProvider client={queryClient}>
            <ToastContainer 
                position='top-center'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
            <App />
        </QueryClientProvider>
    </BrowserRouter>
);
