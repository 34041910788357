import { useQuery } from "react-query";
import agent from "../api/agent";

const getUserInfo = () => {
    return agent.UserService.getInfo();
}

const useGetUserInfo = (options) => {
    return useQuery('user-info', getUserInfo, {
        ...options,
        select: (data) => data,
        fetchOnMount: 'always'
    })
}

export default useGetUserInfo;