import React from 'react';
import Modal from 'react-responsive-modal';
import { Button } from '../ui';

const SupportTicketModal = ({ open, onClose, }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            animationDuration={0}
            center
        >
            <div className="modal-body">
                <h3>CREATE SUPPORT TICKET</h3>
                <hr />
                Please fill out all the fields to create a support ticket.
                <div className="col-lg-12" style={{paddingTop: "20px"}}>
                    {/*----------------------------- User Email -----------------------------*/}
                    <div className="row"style={{paddingBottom: "10px"}}>
                        <div className="col-lg-4 end-lg" style={{marginRight: "10px"}}>
                            <b>EMAIL</b>
                        </div>
                        <div className="col-lg-7" style={{marginLeft: "10px"}}>
                            <input type="text" 
                                style={{width: "100%", padding: '5px 10px'}}
                                name="userEmail"
                                value={''}
                                onChange={() => {}}
                                onBlur={() => {}}
                                placeholder="Email"/>
                                {
                                    // supportTicketModal.errors.userEmail === true && 
                                    // <div style={{color: "red"}}>INVALID EMAIL ADDRESS</div>
                                }
                        </div>
                    </div>
                    {/*----------------------------- Application Name -----------------------------*/}
                    <div className="row"style={{paddingBottom: "10px"}}>
                        <div className="col-lg-4 end-lg" style={{marginRight: "10px"}}>
                            <b>APPLICATION NAME</b>
                        </div>
                        <div className="col-lg-7" style={{marginLeft: "10px"}}>
                            <input type="text" 
                                style={{width: "100%", padding: '5px 10px'}}
                                name="application"
                                value={''}
                                onChange={() => {}}
                                onBlur={() => {}}
                                placeholder="Application Name"/>
                                {
                                    // supportTicketModal.errors.appName === true &&
                                    // <div style={{color: "red"}}>APPLICATION NAME CANNOT BE BLANK</div>
                                }
                        </div>
                    </div>
                    {/*----------------------------- Application URL -----------------------------*/}
                    <div className="row"style={{paddingBottom: "10px"}}>
                        <div className="col-lg-4 end-lg" style={{marginRight: "10px"}}>
                            <b>APPLICATION URL</b>
                        </div>
                        <div className="col-lg-7" style={{marginLeft: "10px"}}>
                            <input type="text" 
                                style={{width: "100%", padding: '5px 10px'}}
                                name="appUrl"
                                value={''}
                                onChange={() => {}}
                                onBlur={() => {}}
                                placeholder="Application URL"/>
                                {
                                    // supportTicketModal.errors.appURL === true &&
                                    // <div style={{color: "red"}}>INVALID URL</div>
                                }
                        </div>
                    </div>
                    {/*----------------------------- Application Issue -----------------------------*/}
                    <div className="row" style={{paddingBottom: "10px"}}>
                        <div className="col-lg-4 end-lg" style={{marginRight: "10px"}}>
                            <b>APPLICATION ISSUE</b>
                        </div>
                        <div className="col-lg-7" style={{marginLeft: "10px"}}>
                            <textarea
                                style={{width: "100%", padding: '5px 10px', height: "200px", resize: "none"}}
                                name="issue"
                                value={''}
                                onChange={() => {}}
                                onBlur={() => {}}/>
                                {
                                    // supportTicketModal.errors.appIssue === true && 
                                    // <div style={{color: "red"}}>APPLICATION ISSUE CANNOT BE BLANK</div>
                                }
                        </div>
                    </div>
                    <hr />
                    {/*----------------------------- Buttons -----------------------------*/}
                    {/* <div className="col-lg-offset-8 col-lg-4"> */}
                        <div className="row end-lg">
                            <Button buttonType="modal-primary">SEND</Button>
                            <Button
                                buttonType="modal-secondary"
                                callback={() => onClose()}
                            >
                                CANCEL
                            </Button>
                        </div>
                    {/* </div> */}
                </div>
            </div>
        </Modal>
    );
}

export default SupportTicketModal;