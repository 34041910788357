import { useQuery } from "react-query";
import agent from "../api/agent";

const getAppSettings = () => {
    return agent.SettingsService.getSettings();
}

const useGetAppSettings = (options) => {
    return useQuery('settings', getAppSettings, {
        ...options,
        select: (data) => data,
        fetchOnMount: 'always'
    })
}

export default useGetAppSettings;