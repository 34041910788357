import React, { useState, useEffect } from 'react';
// import { useAuth } from 'oidc-react';
import { Button } from '../../components/ui';
import { useGetAppSettings, useGetUserInfo } from '../../hooks';
import agent from '../../api/agent';
// import parseJwt from '../../utils/parseJWT';

const User = () => {
    const {
        data: settings,
        isLoading: isLoadingSettings,
        isError: errorSettings
    } = useGetAppSettings({});

    const {
        data: userInfo,
        isLoading: isLoadingUserInfo,
        isError: userInfoError
    } = useGetUserInfo({});

    return (
        <div className="app-user">
            <div className='row center-lg middle-lg app-user-title'>
                {!isLoadingSettings && settings.mainTitle}
            </div>
            <div className="row center-lg middle-lg app-user-name">
                { !isLoadingUserInfo && `${userInfo?.firstName} ${userInfo?.lastName}`}
            </div>
            <div className="row center-lg">
                <Button buttonType='user-btn' callback={() => {window.open(`${settings?.sloUrl}`, "_self")}}>Logout</Button>
            </div>
        </div>
    );
}

export default User;